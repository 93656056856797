.login-background {
  min-height: 100vh;
}

.background-grey {
  background-color: #d8d8d8;
}

.background-white {
  background-color: white;
  border-radius: 4px;
}

.mtop {
  margin-top: 8rem;
}

.card-over {
  border: none !important;
}

.login-logo {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-button {
  font-size: 2em; /* adjust this to make the button text bigger or smaller */
  background-color: black;
  border-color: black;
}

.login-button:hover {
  background-color: rgb(77, 77, 77);
  border-color: rgb(77, 77, 77);
}

.button-div {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
